import React from 'react'
import Hero from "../Components/hero"
import Whatwedo from "../Components/whatwedo"
import Vision from "../Components/Vision"
import AboutUs from "../Components/AboutUs"
import Gallary from "../Components/Gallary"

export default function Home() {
    return (
        <>
            <Hero/>
            <Vision/>
            <AboutUs/>
            <Whatwedo/>
            <Gallary/>
            
            
        </>
    )
}
